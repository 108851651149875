@import "Bootstrap/_functions";
@import "Bootstrap/_variables.scss";
@import "Bootstrap/mixins/_breakpoints.scss";
#section-classes {
    &>div {
        padding-top: 0;
        padding-bottom: 0;
    }
    .classes-section {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        letter-spacing: 1px;
        font-style: italic;
        .content {
            padding-left: 2rem;
            padding-right: 2rem;
            .title {
                margin-bottom: 2rem;
                justify-content: flex-start;
            }
        }
    }
    .classes-item {
        .row {
            align-items: center;
            .classes-item-image,
            .classes-item-text {
                position: relative;
                width: 100%;
                min-height: 1px;
                padding-left: 15px;
                padding-right: 15px;
            }
            .classes-item-image {
                flex: 0 0 25%;
                max-width: 25%;
            }
            .classes-item-text {
                flex: 0 0 75%;
                max-width: 75%;
            }
            @include media-breakpoint-down(sm) {
                .classes-item-image {
                    display: none;
                }
                .classes-item-text {
                    flex: 0 0 100%;
                    max-width: 100%;
                    text-align: center;
                    .title {
                        justify-content: center;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        #english,
        #plastic,
        #education,
        #english {
            background-image: url("../images/classes/english_mobile.png");
        }
        #plastic {
            background-image: url("../images/classes/plastic_mobile.png");
        }
        #education {
            background-image: url("../images/classes/education_mobile.png");
        }
    }
}

.mobile{
    #section-classes {
       
        .classes-item {
            .row {
                
                @include media-breakpoint-down(md) {
                    .classes-item-image {
                        display: none;
                    }
                    .classes-item-text {
                        flex: 0 0 100%;
                        max-width: 100%;
                        text-align: center;
                        .title {
                            justify-content: center;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            #english,
            #plastic,
            #education {
                background-size: cover;
                background-position: center;
                color: $primary-white;
            }
            #english {
                background-image: url("../images/classes/english_mobile.png");
            }
            #plastic {
                background-image: url("../images/classes/plastic_mobile.png");
            }
            #education {
                background-image: url("../images/classes/education_mobile.png");
            }
        }
    }
}