
.container.gallery-container {
    background-color: #fff;
    color: #35373a;
    min-height: 100vh;
    border-radius: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.06);
}

.gallery-container h1 {
    text-align: center;
    margin-top: 70px;
    font-family: 'Droid Sans', sans-serif;
    font-weight: bold;
}

.gallery-container p.page-description {
    text-align: center;
    max-width: 800px;
    margin: 25px auto;
    color: #888;
    font-size: 18px;
}

.tz-gallery {
    padding: 40px;
    a{
        max-height: 200px;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
    }
}

.tz-gallery .lightbox img {
    width: 100%;
    transition: 0.2s ease-in-out;
    box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}


.tz-gallery .lightbox img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 15px rgba(0,0,0,0.3);
}

.tz-gallery a {
    border-radius: 4px;
}

.baguetteBox-button {
    background-color: transparent !important;
}


@media(max-width: 768px) {

    .container.gallery-container {
        border-radius: 0;
    }
}
#baguetteBox-overlay{
    button,
    button:active,
    button:focus,
    button:hover{
        outline: none;
    }
}
 #baguetteBox-overlay{
     display:none;
     opacity:0;
     position:fixed;
     overflow:hidden;
     top:0;
     left:0;
     width:100%;
     height:100%;
     z-index:1000000;
     background-color:#222;
     background-color:rgba(0,0,0,.8);
     -webkit-transition:opacity .5s ease;
     transition:opacity .5s ease;

     &.visible{
        opacity:1;
     }
      .full-image{
        display:inline-block;
        position:relative;
        width:100%;
        height:100%;
        text-align:center;

        figure{

            display:inline;
            margin:0;
            height:100%;
        }
        img{
            display:inline-block;
            width:auto;
            height:auto;
            max-height:100%;
            max-width:100%;
            vertical-align:middle;
            -moz-box-shadow:0 0 8px rgba(0,0,0,.6);
            box-shadow:0 0 8px rgba(0,0,0,.6);
        }
        figcaption{

            display:block;
            position:absolute;
            bottom:0;width:100%;
            text-align:center;
            line-height:1.8;
            white-space:normal;
            color:#ccc;
            background-color:#000;
            background-color:rgba(0,0,0,.6);
            font-family:sans-serif;
        }
      }
    }
    
    @import "_baguetteBox.scss";