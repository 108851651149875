﻿@font-face {
  font-family: "smykifont";
  src: url("../fonts/smykifont.eot");
  src: url("../fonts/smykifont.eot?#iefix") format("embedded-opentype"), url("../fonts/smykifont.woff") format("woff"),
    url("../fonts/smykifont.ttf") format("truetype"), url("../fonts/smykifont.svg#smykifont") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "smykifont" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "smykifont" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-heart:before {
  content: "\e002";
}

.icon-calendar:before {
  content: "\e003";
}

.icon-home:before {
  content: "\e004";
}

.icon-pen:before {
  content: "\e005";
}

.icon-phone:before {
  content: "\e006";
}

.icon-star:before {
  content: "\e007";
}

.icon-galery:before {
  content: "\e008";
}

.icon-banknote:before {
  content: "\e009";
}

.icon-down:before {
  content: "\e010";
}

.icon-mail:before {
  content: "\e011";
}

.icon-time:before {
  content: "\e012";
}

.icon-phone-loc:before {
  content: "\e013";
}

.icon-facebook:before {
  content: "\e014";
}
