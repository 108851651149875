
@import "_variables.scss";
@import "Bootstrap/_functions";
@import "Bootstrap/_variables.scss"; 
@import "Bootstrap/mixins/_breakpoints.scss";

html.mobile{ 
    .carousel-scroll-container{
        bottom: 120px!important;
    }
    
    @include media-breakpoint-down(md){
        font-size: 32px;
        &.landscape{
            font-size: 24px;

            #body-top{
                display: none !important;
            }
            .navbar{
                position: sticky;
            }
            .carousel-scroll-container{
                display: none !important;
            }
        }
    }
}

