@import "Bootstrap/mixins/_breakpoints.scss";

footer {
    .footer-top {
        background-color: $footer-top-background;
        color: $footer-top-color;
        padding: 2rem 1rem;
        .col{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        }

        .footer-top-item{
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0 15px;
            &>div{
                display: flex;
                align-items: center;
            }
            &.start{
                justify-content: flex-start;
            }
            &.end{
                justify-content: flex-end;
            }
            @include media-breakpoint-down(md){
                flex-basis: 100%;
                flex-shrink: 0;
                flex-grow: 0;
                justify-content: center!important;
                &.start{
                    padding-bottom: 1rem;
                }
                &.end{
                    padding-top:1rem;
                }
            }
        }
    }
    .footer-bottom {
        background-color: $footer-bottom-background;
        color: $footer-bottom-color;
        padding: 1rem;
        @include media-breakpoint-down(md){
            .col{
                display: flex;
                justify-content: center;
            }
        }
    }
}