@import "_variables.scss";
@import "Bootstrap/_functions";
@import "Bootstrap/_variables.scss";
@import "Bootstrap/mixins/_breakpoints.scss";
.navbar {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding-top: 0px;
    padding-bottom: 0px;
    .nav-item {
        font-family: $navbar-item-font;
        font-size: $navbar-item-font-size;
    }
    .navbar-toggler-container {
        display: flex;
        align-items: center;
    }
    .navbar-facebook {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        line-height: 1rem;
        padding-right: 1rem;
        font-size: 1.7rem !important;
        color: #3b5998 !important;
        flex-grow: 1;
        text-align: right;
        .icon-facebook {
            margin-left: 1rem;
            cursor: pointer;
        }
    }
    z-index: 10001;
    .navbar-brand {
        color: $primary-green;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .navbar-collapse {
        flex-grow: 0;
    }
}